<template>

   <div>
    <!-- Modal -->
   <div class="card">
                    <img class="card-img-top" :src="img" alt=""/>
                    <div class="card-body">
                        <h5 class="card-title">{{title }}</h5>
                        <p>{{creator !="" ? "Source:" : ""}} <i>{{ creator }}</i>

                        </p>
                        <p class="card-text" >{{ returnBody }}</p>

                        <button type="button" class="btn btn-primary" data-toggle="modal" :data-target="'#exampleModalLong'+id">
                 Full text
                </button> 
                      
                   </div>
                </div>


        <div class="modal fade" :id="'exampleModalLong'+id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                  <img :src="img" alt="" style="width:30%; height:auto;" />

                <h5 style="padding-left:1rem;" class="modal-title" id="exampleModalLongTitle">{{title }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
               <span v-html="body"></span>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                
              </div>
            </div>
          </div>
        </div>
      </div>  

</template>

<script>
export default {

  data() {
      return{

          maxTextLength: 150
      }

  },

  props:['img','title','creator','body','id'],

    

computed: {
    returnBody() {
      return  this.body.length > this.maxTextLength ? this.body.substring(0, this.maxTextLength) + "..." : this.body

    }   

}
}

</script>

<style scope>
/* cards */ 

.card {

  margin: 2rem auto;
  width: 70%;
  border:5px solid #343a40;

}

.card-body {
  color:#fff;
  background-color: #343a40;
}

/* modal */ 

.modal-header, .modal-body, .modal-footer {

  color:#fff;
  background-color: #343a40;

}

.mymodal .modal-open { overflow-y: auto; }


    @media (max-width: 66rem) {
      
       div.container .row .col h2 {
  

        margin-top:4rem;
        margin-bottom: 2rem;
 
        
      }
       

    }


</style>