<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1
          class="welcome_headline"
          style="margin-bottom: 5rem; margin-top: 4rem"
        >
          Welcome to my website!
        </h1>
      </div>
    </div>

    <div class="row" style="margin-left: 2rem">
      <div class="col-md-3 col-6">
        <img src="../../img/vue-logo.png" />
      </div>
      <div class="col-md-3 col-6">
        <img src="../../img/react-icon.png" />
      </div>
      <div class="col-md-3 col-6">
        <img src="../../img/nodeJS.png" />
      </div>
      <div class="col-md-3 col-6">
        <img src="../../img/firebase.png" />
      </div>
    </div>
    <div class="row" style="margin-left: 2rem">
      <div class="col-md-3 col-6">
        <img src="../../img/bootstrap-logo.png" />
      </div>
      <div class="col-md-3 col-6">
        <img src="../../img/html5_canvas.jpg" />
      </div>
      <div class="col-md-3 col-6">
        <div class="col">
          <img src="../../img/svg_logo.png" />
        </div>
      </div>
      <div class="col-md-3 col-6">
        <img src="../../img/ui_ux.png" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button @click="$router.push('about')" class="btn btn-lg btn-success">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",

  props: {
    buttonText: String,
  },
};
</script>

<style scoped>
img {
  max-width: 70%;
  max-height: 70%;
  display: block;
}

div.container {
  z-index: -1;
  text-align: center;
}

h1.welcome_headline {
  margin-top: 2rem;
  color: #fff;
  font-style: italic;
}

div.container div div button {
  margin: auto;
  display: block;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

canvas#matrix {
  margin-top: 50px;
}

/* MEDIA QUERIES */

@media (max-width: 1000px) {
  canvas#matrix {
    width: 60%;
  }
}

@media (max-width: 600px) {
  canvas#matrix {
    width: 30%;
    display: none;
  }
}
</style>
