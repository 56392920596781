<template>
  <div class="container">
    <div class="design-header">
      <!-- page title -->

      <div class="row">
        <div class="col-md-8">
          <h2 class="text-warning">{{ pageTitle }}</h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h5 class="text-warning">{{ mapTitle }}</h5>

        <div class="svg-container">
          <img src="../../img/svg/honduras_coffee_large.svg" />
        </div>
      </div>
    </div>

    <br /><br />

    <div class="row">
      <div class="col-md-12">
        <h5 class="text-warning">{{ mapTitle2 }}</h5>

        <div class="svg-container">
          <img src="../../img/svg/coffee_agalta.svg" />
        </div>
      </div>
    </div>

    <br /><br />
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-warning">{{ mapTitle3 }}</h5>

        <div class="svg-container">
          <img src="../../img/svg/coffee_el_paraiso_comayagua.svg" />
        </div>
      </div>
    </div>

    <br /><br />
    <div class="svg-businesscard">
      <div class="row">
        <div class="col-md-6">
          <h5 class="text-warning">{{ businessCardV }}</h5>

          <svg
            sodipodi:docname="businesscard_verso_english_web.svg"
            inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
            id="svg8"
            version="1.1"
            width="80%"
            height="80%"
            viewBox="0 0 85 55"
            inkscape:export-filename="C:\Users\marco\OneDrive\Desktop\freelancing\card_de_visite\businesscard_verso.png"
            inkscape:export-xdpi="96"
            inkscape:export-ydpi="96"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
          >
            <defs id="defs2">
              <rect
                x="48.081788"
                y="2.6249997"
                width="39.918212"
                height="12.716271"
                id="rect3238"
              />
              <linearGradient id="linearGradient851" inkscape:swatch="solid">
                <stop
                  style="stop-color: #ffffff; stop-opacity: 1"
                  offset="0"
                  id="stop849"
                />
              </linearGradient>
            </defs>
            <sodipodi:namedview
              id="base"
              pagecolor="#ffffff"
              bordercolor="#666666"
              borderopacity="1.0"
              inkscape:pageopacity="0.0"
              inkscape:pageshadow="2"
              inkscape:zoom="2.0000001"
              inkscape:cx="155.24999"
              inkscape:cy="123.74999"
              inkscape:document-units="in"
              inkscape:current-layer="layer1"
              inkscape:document-rotation="0"
              showgrid="false"
              borderlayer="true"
              inkscape:showpageshadow="false"
              inkscape:snap-page="false"
              inkscape:window-width="1920"
              inkscape:window-height="1017"
              inkscape:window-x="1912"
              inkscape:window-y="-8"
              inkscape:window-maximized="1"
              inkscape:pagecheckerboard="0"
            >
              <sodipodi:guide
                position="0,55"
                orientation="0,321.25984"
                id="guide833"
              />
              <sodipodi:guide
                position="85,55"
                orientation="207.87402,0"
                id="guide835"
              />
              <sodipodi:guide
                position="85,0"
                orientation="0,-321.25984"
                id="guide837"
              />
              <sodipodi:guide
                position="0,0"
                orientation="-207.87402,0"
                id="guide839"
              />
              <sodipodi:guide
                position="-3.0000002,58"
                orientation="230.55118,0"
                id="guide3149"
              />
              <sodipodi:guide
                position="-3.0000002,-3.0000003"
                orientation="0,343.93701"
                id="guide3151"
              />
              <sodipodi:guide
                position="88,-3.0000003"
                orientation="-230.55118,0"
                id="guide3153"
              />
              <sodipodi:guide
                position="88,58"
                orientation="0,-343.93701"
                id="guide3155"
              />
              <sodipodi:guide
                position="2.1249998,52.375"
                orientation="188.0315,0"
                id="guide3161"
              />
              <sodipodi:guide
                position="2.1249998,2.6249997"
                orientation="0,305.19685"
                id="guide3163"
              />
              <sodipodi:guide
                position="82.875,2.6249997"
                orientation="-188.0315,0"
                id="guide3165"
              />
              <sodipodi:guide
                position="38.099998,52.221875"
                orientation="0,-305.19685"
                id="guide3167"
              />
            </sodipodi:namedview>
            <metadata id="metadata5">
              <rdf:RDF>
                <cc:Work rdf:about="">
                  <dc:format>image/svg+xml</dc:format>
                  <dc:type
                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                  />
                </cc:Work>
              </rdf:RDF>
            </metadata>
            <g inkscape:groupmode="layer" id="layer2" inkscape:label="Ebene 0">
              <rect
                style="
                  fill: #ffffff;
                  fill-rule: evenodd;
                  stroke: #ffffff;
                  stroke-width: 0.200365;
                  stroke-opacity: 1;
                "
                id="rect1257"
                width="38.821537"
                height="57.943405"
                x="44.053291"
                y="0.00017010783"
              />
              <path
                style="
                  fill: #ffffff;
                  fill-rule: evenodd;
                  stroke: #0000ff;
                  stroke-width: 0;
                  stroke-miterlimit: 0;
                  stroke-dasharray: none;
                  opacity: 0;
                "
                d="M 167.74999,109.49999 V 0.74999996 h 72.5 72.49999 V 109.49999 v 108.75 h -72.49999 -72.5 z"
                id="path1296"
                transform="scale(0.26458333)"
              />
            </g>
            <g
              inkscape:label="Ebene 1"
              inkscape:groupmode="layer"
              id="layer1"
              style="display: inline"
            >
              <rect
                style="
                  fill: #ffffff;
                  fill-rule: evenodd;
                  stroke: none;
                  stroke-width: 0.264583;
                "
                id="rect3141"
                width="42.731125"
                height="61"
                x="-3.0000002"
                y="-3.0000002"
                sodipodi:insensitive="true"
              />
              <rect
                style="
                  opacity: 0.96;
                  fill: #b52f2a;
                  fill-opacity: 1;
                  fill-rule: evenodd;
                  stroke-width: 0.269729;
                "
                id="rect3169"
                width="46.964802"
                height="61"
                x="-3.0000002"
                y="-3.0000002"
              />
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10.5833px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #ffffff;
                  fill-opacity: 0;
                  stroke: none;
                  stroke-width: 0.264583;
                "
                x="7.1093693"
                y="8.980257"
                id="text3173"
              >
                <tspan
                  sodipodi:role="line"
                  id="tspan3171"
                  x="7.1093693"
                  y="8.980257"
                  style="fill: #ffffff; fill-opacity: 0; stroke-width: 0.264583"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-variant: normal;
                  font-weight: normal;
                  font-stretch: normal;
                  font-size: 8.42795px;
                  line-height: 1.25;
                  font-family: 'Bauhaus 93';
                  -inkscape-font-specification: 'Bauhaus 93, ';
                  fill: #d35f5f;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.210699;
                "
                x="4.401989"
                y="9.8383236"
                id="text3185"
                transform="scale(0.66225444,1.5099936)"
              >
                <tspan
                  sodipodi:role="line"
                  x="4.401989"
                  y="9.8383236"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-family: 'Bauhaus 93';
                    -inkscape-font-specification: 'Bauhaus 93, ';
                    fill: #d35f5f;
                    stroke-width: 0.210699;
                  "
                  id="tspan3187"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10.5833px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.264583;
                "
                x="27.876211"
                y="12.534941"
                id="text3197"
              >
                <tspan
                  sodipodi:role="line"
                  id="tspan3195"
                  x="27.876211"
                  y="12.534941"
                  style="stroke-width: 0.264583"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-variant: normal;
                  font-weight: normal;
                  font-stretch: normal;
                  font-size: 4.41422px;
                  line-height: 1.25;
                  font-family: 'Berlin Sans FB';
                  -inkscape-font-specification: 'Berlin Sans FB';
                  fill: #f5db91;
                  fill-opacity: 0.870588;
                  stroke: none;
                  stroke-width: 0.110356;
                "
                x="27.110733"
                y="3.3764317"
                id="text3211"
                transform="scale(0.78391631,1.2756464)"
              >
                <tspan
                  sodipodi:role="line"
                  x="27.110733"
                  y="3.3764317"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-family: 'Berlin Sans FB';
                    -inkscape-font-specification: 'Berlin Sans FB';
                    text-align: center;
                    text-anchor: middle;
                    fill: #f5db91;
                    fill-opacity: 0.870588;
                    stroke-width: 0.110356;
                  "
                  id="tspan3230"
                />
                <tspan
                  sodipodi:role="line"
                  x="27.110733"
                  y="8.894207"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-family: 'Berlin Sans FB';
                    -inkscape-font-specification: 'Berlin Sans FB';
                    text-align: center;
                    text-anchor: middle;
                    fill: #f5db91;
                    fill-opacity: 0.870588;
                    stroke-width: 0.110356;
                  "
                  id="tspan3826"
                >
                  WEB
                </tspan>
                <tspan
                  sodipodi:role="line"
                  x="27.110733"
                  y="14.411982"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-family: 'Berlin Sans FB';
                    -inkscape-font-specification: 'Berlin Sans FB';
                    text-align: center;
                    text-anchor: middle;
                    fill: #f5db91;
                    fill-opacity: 0.870588;
                    stroke-width: 0.110356;
                  "
                  id="tspan9509"
                >
                  DEVELOPMENT
                </tspan>
                <tspan
                  sodipodi:role="line"
                  x="27.110733"
                  y="19.929756"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-family: 'Berlin Sans FB';
                    -inkscape-font-specification: 'Berlin Sans FB';
                    text-align: center;
                    text-anchor: middle;
                    fill: #f5db91;
                    fill-opacity: 0.870588;
                    stroke-width: 0.110356;
                  "
                  id="tspan3822"
                >
                  &amp;
                </tspan>
                <tspan
                  sodipodi:role="line"
                  x="27.110733"
                  y="25.447531"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-family: 'Berlin Sans FB';
                    -inkscape-font-specification: 'Berlin Sans FB';
                    text-align: center;
                    text-anchor: middle;
                    fill: #f5db91;
                    fill-opacity: 0.870588;
                    stroke-width: 0.110356;
                  "
                  id="tspan3824"
                >
                  DESIGN
                </tspan>
              </text>
              <text
                xml:space="preserve"
                id="text3236"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10.5833px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  white-space: pre;
                  shape-inside: url(#rect3238);
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                "
              />
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10.5833px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.264583;
                "
                x="53.133183"
                y="15.154182"
                id="text3256"
              >
                <tspan
                  sodipodi:role="line"
                  x="53.133183"
                  y="15.154182"
                  style="stroke-width: 0.264583"
                  id="tspan3258"
                />
                <tspan
                  sodipodi:role="line"
                  x="53.133183"
                  y="28.383307"
                  style="stroke-width: 0.264583"
                  id="tspan3262"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10.5833px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.264583;
                "
                x="60.61673"
                y="31.992163"
                id="text3276"
              >
                <tspan
                  sodipodi:role="line"
                  id="tspan3274"
                  x="60.61673"
                  y="31.992163"
                  style="stroke-width: 0.264583"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 10.5833px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.264583;
                "
                x="57.062046"
                y="108.8856"
                id="text3300"
              >
                <tspan
                  sodipodi:role="line"
                  id="tspan3298"
                  x="57.062046"
                  y="108.8856"
                  style="stroke-width: 0.264583"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.576908"
                y="25.527866"
                id="text3328"
                transform="scale(0.95092624,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.576908"
                  y="25.527866"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Copperplate Gothic Light';
                    -inkscape-font-specification: 'Copperplate Gothic Light, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3320"
                />
                <tspan
                  sodipodi:role="line"
                  x="48.576908"
                  y="28.458572"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Copperplate Gothic Light';
                    -inkscape-font-specification: 'Copperplate Gothic Light, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3322"
                />
                <tspan
                  sodipodi:role="line"
                  x="48.576908"
                  y="31.389278"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Copperplate Gothic Light';
                    -inkscape-font-specification: 'Copperplate Gothic Light, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3324"
                />
                <tspan
                  sodipodi:role="line"
                  x="48.576908"
                  y="33.928352"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Copperplate Gothic Light';
                    -inkscape-font-specification: 'Copperplate Gothic Light, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3326"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-variant: normal;
                  font-weight: bold;
                  font-stretch: normal;
                  font-size: 4.14487px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  -inkscape-font-specification: 'sans-serif, Bold';
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-variant-numeric: normal;
                  font-variant-east-asian: normal;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.103621;
                "
                x="67.194031"
                y="17.762791"
                id="text3244"
                transform="scale(0.68938706,1.4505639)"
              >
                <tspan
                  sodipodi:role="line"
                  id="tspan3242"
                  x="67.194031"
                  y="17.762791"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-stretch: normal;
                    font-size: 4.14487px;
                    font-family: sans-serif;
                    -inkscape-font-specification: 'sans-serif, Bold';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.103621;
                  "
                >
                  MARCO ALVARADO
                </tspan>
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-variant: normal;
                  font-weight: bold;
                  font-stretch: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  -inkscape-font-specification: 'sans-serif, Bold';
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-variant-numeric: normal;
                  font-variant-east-asian: normal;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.773651"
                y="27.55591"
                id="text3742"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="27.55591"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: sans-serif;
                    -inkscape-font-specification: 'sans-serif, Bold';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3264"
                >
                  FRONTEND DEVELOPER
                </tspan>
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.773651"
                y="30.11768"
                id="text3745"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="30.11768"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Arial Rounded MT Bold';
                    -inkscape-font-specification: 'Arial Rounded MT Bold, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3246"
                />
              </text>

              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: bold;
                  font-size: 2.03125998px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                  -inkscape-font-specification: 'sans-serif, Bold';
                  font-stretch: normal;
                  font-variant: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-variant-numeric: normal;
                  font-variant-east-asian: normal;
                "
                x="48.773651"
                y="35.241215"
                id="text3751"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="35.241215"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-stretch: normal;
                    font-size: 2.03125998px;
                    font-family: sans-serif;
                    -inkscape-font-specification: 'sans-serif, Bold';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3278"
                >
                  Amsterdam Zuidoost
                </tspan>
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: bold;
                  font-size: 2.03125998px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                  -inkscape-font-specification: 'sans-serif, Bold';
                  font-stretch: normal;
                  font-variant: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-variant-numeric: normal;
                  font-variant-east-asian: normal;
                "
                x="48.773651"
                y="37.802986"
                id="text3754"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="37.802986"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-stretch: normal;
                    font-size: 2.03125998px;
                    font-family: sans-serif;
                    -inkscape-font-specification: 'sans-serif, Bold';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3294"
                >
                  +31(0) 684 318951
                </tspan>
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.773651"
                y="40.364754"
                id="text3757"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="40.364754"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Arial Rounded MT Bold';
                    -inkscape-font-specification: 'Arial Rounded MT Bold, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3282"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: bold;
                  font-size: 2.03125998px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                  -inkscape-font-specification: 'sans-serif, Bold';
                  font-stretch: normal;
                  font-variant: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-variant-numeric: normal;
                  font-variant-east-asian: normal;
                "
                x="48.773651"
                y="42.926521"
                id="text3760"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="42.926521"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-stretch: normal;
                    font-size: 2.03125998px;
                    font-family: sans-serif;
                    -inkscape-font-specification: 'sans-serif, Bold';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3290"
                >
                  marcoalvarado2@gmail.com
                </tspan>
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: bold;
                  font-size: 2.03125998px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                  -inkscape-font-specification: 'sans-serif, Bold';
                  font-stretch: normal;
                  font-variant: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-variant-numeric: normal;
                  font-variant-east-asian: normal;
                "
                x="48.773651"
                y="45.488289"
                id="text3763"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="45.488289"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: bold;
                    font-stretch: normal;
                    font-size: 2.03125998px;
                    font-family: sans-serif;
                    -inkscape-font-specification: 'sans-serif, Bold';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3302"
                >
                  https://dev.malvarado.net
                </tspan>
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.773651"
                y="48.050056"
                id="text3766"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="48.050056"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Arial Rounded MT Bold';
                    -inkscape-font-specification: 'Arial Rounded MT Bold, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3296"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.773651"
                y="50.611828"
                id="text3769"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="50.611828"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Arial Rounded MT Bold';
                    -inkscape-font-specification: 'Arial Rounded MT Bold, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3292"
                />
              </text>
              <text
                xml:space="preserve"
                style="
                  font-style: normal;
                  font-weight: normal;
                  font-size: 2.03126px;
                  line-height: 1.25;
                  font-family: sans-serif;
                  fill: #000000;
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 0.0507813;
                "
                x="48.773651"
                y="53.173595"
                id="text3772"
                transform="scale(0.95092623,1.0516063)"
              >
                <tspan
                  sodipodi:role="line"
                  x="48.773651"
                  y="53.173595"
                  style="
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-size: 2.03126px;
                    font-family: 'Arial Rounded MT Bold';
                    -inkscape-font-specification: 'Arial Rounded MT Bold, Normal';
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-variant-numeric: normal;
                    font-variant-east-asian: normal;
                    stroke-width: 0.0507813;
                  "
                  id="tspan3286"
                />
              </text>
              <g
                style="
                  opacity: 1;
                  fill: none;
                  fill-rule: nonzero;
                  stroke: none;
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  stroke-dasharray: none;
                "
                transform="matrix(0.03692335,0,0,0.03692335,57.299884,5.0590646)"
                id="g3265"
              >
                <circle
                  cx="148.5"
                  cy="148.5"
                  r="148.5"
                  style="
                    opacity: 1;
                    fill: #f5db91;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="circle3253"
                />
                <path
                  d="M 296.997,148.747 260.5,112.25 l -224,93.333 101.621,91.05 c 3.43,0.237 6.889,0.367 10.379,0.367 81.932,0 148.363,-66.353 148.497,-148.253 z"
                  style="
                    opacity: 1;
                    fill: #ceae61;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  stroke-linecap="round"
                  id="path3255"
                />
                <rect
                  x="167.17"
                  y="112.25"
                  rx="0"
                  ry="0"
                  width="93.330002"
                  height="93.330002"
                  style="
                    opacity: 1;
                    fill: #c0392b;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect3257"
                />
                <rect
                  x="36.5"
                  y="149.58"
                  rx="0"
                  ry="0"
                  width="93.330002"
                  height="56"
                  style="
                    opacity: 1;
                    fill: #c0392b;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect3259"
                />
                <rect
                  x="111.17"
                  y="74.919998"
                  rx="0"
                  ry="0"
                  width="74.669998"
                  height="130.67"
                  style="
                    opacity: 1;
                    fill: #79302a;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect3261"
                />
                <rect
                  x="139.17"
                  y="93.580002"
                  rx="0"
                  ry="0"
                  width="18.67"
                  height="56"
                  style="
                    opacity: 1;
                    fill: #e67e22;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect3263"
                />
              </g>
              <g
                id="g4458"
                transform="matrix(0.26458333,0,0,0.26458333,-94.325527,-48.813967)"
              >
                <g
                  style="
                    opacity: 1;
                    fill: none;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  transform="matrix(0.08896289,0,0,0.08896289,413.37288,322.9848)"
                  id="g4228"
                >
                  <circle
                    cx="256"
                    cy="256"
                    r="256"
                    style="
                      opacity: 1;
                      fill: #f5db91;
                      fill-opacity: 1;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="circle4170"
                  />
                  <polygon
                    points="137.85,98.46 137.85,413.15 374.15,413.15 374.15,138.27 334.35,98.46 "
                    style="
                      opacity: 1;
                      fill: #3b4250;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="polygon4172"
                  />
                  <polygon
                    points="334.35,98.46 334.35,138.27 374.15,138.27 "
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="polygon4174"
                  />
                  <rect
                    x="282.45999"
                    y="322.26999"
                    rx="0"
                    ry="0"
                    width="42.459999"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4176"
                  />
                  <rect
                    x="177.23"
                    y="204.11"
                    rx="0"
                    ry="0"
                    width="78.150002"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4178"
                  />
                  <rect
                    x="216.62"
                    y="177.03999"
                    rx="0"
                    ry="0"
                    width="78.769997"
                    height="59.080002"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4180"
                  />
                  <rect
                    x="216.62"
                    y="354.26999"
                    rx="0"
                    ry="0"
                    width="78.769997"
                    height="39.380001"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4182"
                  />
                  <rect
                    x="216.62"
                    y="314.88"
                    rx="0"
                    ry="0"
                    width="78.769997"
                    height="19.690001"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4184"
                  />
                  <polygon
                    points="295.38,137.65 256,157.34 216.62,137.65 256,117.96 "
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="polygon4186"
                  />
                  <polygon
                    points="295.38,275.5 256,295.19 216.62,275.5 256,255.81 "
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="polygon4188"
                  />
                  <rect
                    x="253.53999"
                    y="138.27"
                    rx="0"
                    ry="0"
                    width="4.9200001"
                    height="235.69"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4190"
                  />
                  <rect
                    x="243.08"
                    y="135.19"
                    rx="0"
                    ry="0"
                    width="24.610001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4192"
                  />
                  <rect
                    x="236.31"
                    y="273.04001"
                    rx="0"
                    ry="0"
                    width="39.380001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4194"
                  />
                  <rect
                    x="231.38"
                    y="366.57999"
                    rx="0"
                    ry="0"
                    width="19.690001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4196"
                  />
                  <rect
                    x="260.92001"
                    y="366.57999"
                    rx="0"
                    ry="0"
                    width="9.8500004"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4198"
                  />
                  <rect
                    x="231.38"
                    y="376.42001"
                    rx="0"
                    ry="0"
                    width="49.23"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4200"
                  />
                  <rect
                    x="225.85001"
                    y="209.03999"
                    rx="0"
                    ry="0"
                    width="59.080002"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4202"
                  />
                  <rect
                    x="225.85001"
                    y="218.88"
                    rx="0"
                    ry="0"
                    width="19.690001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4204"
                  />
                  <rect
                    x="255.38"
                    y="218.88"
                    rx="0"
                    ry="0"
                    width="9.8500004"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4206"
                  />
                  <rect
                    x="225.85001"
                    y="199.19"
                    rx="0"
                    ry="0"
                    width="29.540001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4208"
                  />
                  <rect
                    x="265.23001"
                    y="199.19"
                    rx="0"
                    ry="0"
                    width="19.690001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4210"
                  />
                  <rect
                    x="225.85001"
                    y="189.34"
                    rx="0"
                    ry="0"
                    width="49.23"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4212"
                  />
                  <rect
                    x="225.85001"
                    y="322.26999"
                    rx="0"
                    ry="0"
                    width="29.540001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4214"
                  />
                  <rect
                    x="265.23001"
                    y="322.26999"
                    rx="0"
                    ry="0"
                    width="19.690001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4216"
                  />
                  <rect
                    x="315.07999"
                    y="314.88"
                    rx="0"
                    ry="0"
                    width="39.380001"
                    height="19.690001"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4218"
                  />
                  <rect
                    x="157.53999"
                    y="186.88"
                    rx="0"
                    ry="0"
                    width="39.380001"
                    height="39.380001"
                    style="
                      opacity: 1;
                      fill: #ffffff;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4220"
                  />
                  <rect
                    x="167.38"
                    y="199.19"
                    rx="0"
                    ry="0"
                    width="19.690001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4222"
                  />
                  <rect
                    x="167.38"
                    y="209.03999"
                    rx="0"
                    ry="0"
                    width="9.8500004"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4224"
                  />
                  <rect
                    x="324.31"
                    y="322.26999"
                    rx="0"
                    ry="0"
                    width="19.690001"
                    height="4.9200001"
                    style="
                      opacity: 1;
                      fill: #242c3c;
                      fill-rule: nonzero;
                      stroke: none;
                      stroke-width: 1;
                      stroke-linecap: butt;
                      stroke-linejoin: miter;
                      stroke-miterlimit: 10;
                      stroke-dasharray: none;
                    "
                    id="rect4226"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div class="col-md-6">
          <h5 class="text-warning">{{ businessCardR }}</h5>

          <svg
            xmlns:osb="http://www.openswatchbook.org/uri/2009/osb"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            width="80%"
            height="80%"
            viewBox="0 0 85 55"
            version="1.1"
            id="svg8"
            inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
            sodipodi:docname="businesscard_recto.svg"
          >
            <defs id="defs2">
              <linearGradient id="linearGradient851" osb:paint="solid">
                <stop
                  style="stop-color: #ffffff; stop-opacity: 1"
                  offset="0"
                  id="stop849"
                />
              </linearGradient>
            </defs>
            <sodipodi:namedview
              id="base"
              pagecolor="#ffffff"
              bordercolor="#666666"
              borderopacity="1.0"
              inkscape:pageopacity="0.0"
              inkscape:pageshadow="2"
              inkscape:zoom="1.4142136"
              inkscape:cx="107.42571"
              inkscape:cy="122.24237"
              inkscape:document-units="in"
              inkscape:current-layer="layer1"
              inkscape:document-rotation="0"
              showgrid="false"
              borderlayer="true"
              inkscape:showpageshadow="false"
              inkscape:snap-page="false"
              inkscape:window-width="1536"
              inkscape:window-height="801"
              inkscape:window-x="-8"
              inkscape:window-y="-8"
              inkscape:window-maximized="1"
            >
              <sodipodi:guide
                position="0,55"
                orientation="0,321.25984"
                id="guide833"
              />
              <sodipodi:guide
                position="85,55"
                orientation="207.87402,0"
                id="guide835"
              />
              <sodipodi:guide
                position="85,0"
                orientation="0,-321.25984"
                id="guide837"
              />
              <sodipodi:guide
                position="0,0"
                orientation="-207.87402,0"
                id="guide839"
              />
              <sodipodi:guide
                position="-3.0000002,58"
                orientation="230.55118,0"
                id="guide3149"
              />
              <sodipodi:guide
                position="-3.0000002,-3.0000003"
                orientation="0,343.93701"
                id="guide3151"
              />
              <sodipodi:guide
                position="88,-3.0000003"
                orientation="-230.55118,0"
                id="guide3153"
              />
              <sodipodi:guide
                position="88,58"
                orientation="0,-343.93701"
                id="guide3155"
              />
              <sodipodi:guide
                position="2.1249998,52.375"
                orientation="188.0315,0"
                id="guide3161"
              />
              <sodipodi:guide
                position="2.1249998,2.6249997"
                orientation="0,305.19685"
                id="guide3163"
              />
              <sodipodi:guide
                position="82.875,2.6249997"
                orientation="-188.0315,0"
                id="guide3165"
              />
              <sodipodi:guide
                position="82.875,52.375"
                orientation="0,-305.19685"
                id="guide3167"
              />
            </sodipodi:namedview>
            <metadata id="metadata5">
              <rdf:RDF>
                <cc:Work rdf:about="">
                  <dc:format>image/svg+xml</dc:format>
                  <dc:type
                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                  />
                  <dc:title />
                </cc:Work>
              </rdf:RDF>
            </metadata>
            <g inkscape:label="Ebene 1" inkscape:groupmode="layer" id="layer1">
              <rect
                style="
                  fill: #b52f2a;
                  fill-rule: evenodd;
                  stroke: none;
                  stroke-width: 0.264583;
                  fill-opacity: 1;
                "
                id="rect3141"
                width="91"
                height="61"
                x="-3.0000002"
                y="-3.0000002"
              />

              <g
                style="
                  opacity: 1;
                  fill: none;
                  fill-rule: nonzero;
                  stroke: none;
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-miterlimit: 10;
                  stroke-dasharray: none;
                "
                transform="matrix(0.10583333,0,0,0.10583333,25.256168,11.224518)"
                id="g2401"
              >
                <circle
                  cx="148.5"
                  cy="148.5"
                  r="148.5"
                  style="
                    opacity: 1;
                    fill: #f5db91;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="circle2389"
                />
                <path
                  d="M 296.997,148.747 260.5,112.25 l -224,93.333 101.621,91.05 c 3.43,0.237 6.889,0.367 10.379,0.367 81.932,0 148.363,-66.353 148.497,-148.253 z"
                  style="
                    opacity: 1;
                    fill: #ceae61;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  stroke-linecap="round"
                  id="path2391"
                />
                <rect
                  x="167.17"
                  y="112.25"
                  rx="0"
                  ry="0"
                  width="93.330002"
                  height="93.330002"
                  style="
                    opacity: 1;
                    fill: #c0392b;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect2393"
                />
                <rect
                  x="36.5"
                  y="149.58"
                  rx="0"
                  ry="0"
                  width="93.330002"
                  height="56"
                  style="
                    opacity: 1;
                    fill: #c0392b;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect2395"
                />
                <rect
                  x="111.17"
                  y="74.919998"
                  rx="0"
                  ry="0"
                  width="74.669998"
                  height="130.67"
                  style="
                    opacity: 1;
                    fill: #79302a;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect2397"
                />
                <rect
                  x="139.17"
                  y="93.580002"
                  rx="0"
                  ry="0"
                  width="18.67"
                  height="56"
                  style="
                    opacity: 1;
                    fill: #e67e22;
                    fill-rule: nonzero;
                    stroke: none;
                    stroke-width: 1;
                    stroke-linecap: butt;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                  "
                  id="rect2399"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    mapTitle: String,
    mapTitle2: String,
    mapTitle3: String,
    businessCardV: String,
    businessCardR: String,
  },
};
</script>

<style scoped>
.design-header {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.svg-container {
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.svg-businesscard {
  margin-bottom: 5rem;
}

img {
  width: 100%;
  height: auto;
}
</style>