<template>

   <nasa
    :pageTitle="title"
   
   ></nasa>


</template>

<script>
    import Nasa from '../../views/Nasa.vue';

    export default {
        name: 'EnNasa',

        components: { Nasa },

        data() {
            return {

             title: "NASA API",
            

            }
        }

    }

</script>