<template>

  <div></div>
  <dialog open>
    <header>
      <slot name="header">
        <h2 class="text-warning">{{ title }}</h2>
      </slot>
    </header>
      <section>
           <slot>

           </slot>
        
      </section>
      
  </dialog>
</template>

<script>

  export default {

    props:['title']
  }

    
</script>

<style scoped>
  div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  background-color: #343a40;
  color: white;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
  background: #808080;
  color:#fff;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  background: #808080;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}


</style>
