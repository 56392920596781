<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 class="text-warning">{{ pageTitle }}</h2>
      </div>
    </div>

    <div class="row height d-flex justify-content-center align-items-center">
      <div class="col-md-12" style="max-width: 40rem">
        <form @submit.prevent="searchNasaContent(search)">
          <div class="form">
            <i class="fa fa-search"></i>
            <input
              type="text"
              class="form-control form-input"
              v-model="search"
              placeholder="Search your favorite Nasa topic"
            />
          </div>
        </form>
      </div>
    </div>
    <!-- API SEARCH RESULTS -->

    <div class="row height d-flex justify-content-center">
      <div
        class="col-md-12 col-lg-12 col-sm-12"
        v-for="(result, idx) in results"
        :key="idx"
      >
        <nasa-content
          :img="result.links[0].href"
          :title="result.data[0].title"
          :creator="result.data[0].secondary_creator"
          :body="result.data[0].description"
          :id="idx"
        ></nasa-content>
      </div>
    </div>

    <div
      class="row justify-content-center align-items-center"
      style="margin-top: 4rem"
    >
      <div class="col">
        <div class="nasa_logo">
          <img class="nasa" src="../../img/nasa_logo.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NasaContent from "./NasaContent.vue";
import axios from "axios";

export default {
  name: "Nasa",

  components: {
    NasaContent,
  },

  props: {
    pageTitle: String,
  },

  data() {
    return {
      //return process.env.VUE_APP_NASA_API;

      search: "",
      results: "",
    };
  },

  computed: {
    showResults() {
      return this.results;
    },
  },

  methods: {
    searchNasaContent(query) {
      axios
        .get(`https://images-api.nasa.gov/search?q=${query}&media_type=image`)
        .then((response) => {
          this.results = response.data.collection.items.slice(0, 40);

          //  console.log(response.data.collection.items.slice(0,40));
          this.search = "";
        });
    },
  },
};
</script>

<style scoped>
div.container .row .col {
  color: #fff;
}

div.container div div h3 {
  z-index: -1;
  color: #fff;
}

div.container .row .col h2 {
  display: inline-block;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

div.container {
  margin-bottom: 3rem;
}

/* SEARCH BAR */

.form {
  position: relative;
}

.form .fa-search {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #9ca3af;
  cursor: pointer;
}

.form span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.left-pan {
  padding-left: 7px;
}

.left-pan i {
  padding-left: 10px;
}

.form-input {
  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
  border: none;
}

/* img */

.nasa_logo {
  margin: 0 auto;
  text-align: center;
}

img.nasa {
  width: 50%;
  height: auto;
}
</style>