<template>

  <div class="container-fluid">

    <the-header
    :profTitlte="profTitle"
    :home="home"
    :projects="projects"
    :blog="blog"
    :design="design"
    :about="about"
    :contact="contact"
    :nasa="nasa"
    :path="path"
    ></the-header>
 
  </div>

  
</template>



<script>

    import TheHeader from "../../TheHeader.vue";
    

    export default {
       components: { TheHeader },

     data() {
        return {
        
          profTitle: "WEB DEVELOPER",
          home: "Home",
          projects: "Projekte",
          blog: "Blog",
          design: "Design",
          about: "About",
          contact: "Kontakt",
          nasa: "NASA",
          path:"de"
       

            }},
         methods: {},   


        
    }


</script>